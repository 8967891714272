<template>
  <v-row justify="center">
    <v-dialog
      v-model="trustedContactDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Tiens de confiance</span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="!trustedContact">
              <v-alert
                text
                dense
                type="error"
              >
                L'utilisateur n'a pas de tiers de confiance
              </v-alert>
            </p>

            <div v-if="trustedContact">
              <ul>
                <li><span class="font-weight-bold">Nom :</span> {{ trustedContact.lastName }}</li>
                <li><span class="font-weight-bold">Prénom :</span> {{ trustedContact.firstName }}</li>
                <li><span class="font-weight-bold">E-mail :</span> {{ trustedContact.email }}</li>
                <li><span class="font-weight-bold">Adresse :</span>{{ trustedContact.address }}</li>
              </ul>
            </div>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>

          <button type="button" v-on:click="closeTrustedContactDialog()" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content">Fermer</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'TrustedContactDialog',
  props: {
    trustedContact: Object,
    trustedContactDialogState: Boolean
  },
  computed: {
    folders () {
      return this.$store.getters.getFolders
    }
  },
  methods: {
    closeTrustedContactDialog () {
      this.$emit('closeTrustedContactDialog', false)
    }
  }
}
</script>
