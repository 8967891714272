<template>
  <div class="text-center">
    <v-dialog
      v-model="resetPasswordLinkDialogState"
      width="600"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('message.reset_password') }}
        </v-card-title>

        <v-card-text>
          {{ $t('message.no_reset_request') }} <strong v-if="passwordResetExpiresAt"> ({{ passwordResetExpiresAt }}) </strong>.
        </v-card-text>

        <v-card-text>

          <div class="form-group">

            <div v-if="error" class="my-5">
              <v-alert dense text type="error">
                <span>{{ message }}</span>
              </v-alert>

            </div>

            <v-input class="form-control text-small" v-model="resetUrl" disabled> {{ resetUrl }} </v-input>

            <div v-if="loading" class="loading-partial my-5">
              <div class="dot-flashing"></div>
            </div>

            <div class="mt-10">
              <v-btn v-if="resetUrl" class="bg-primary text-white mr-5" @click="copyLink()" :disabled="loading">
                {{ $t('message.copy_link') }}
              </v-btn>
              <v-btn class="bg-primary text-white" :class="loading ? '' : 'mx-5'" @click="generateLink(user)" :disabled="loading">
                {{ resetUrl ? $t('message.generate_new_link') : $t('message.generate_link') }}
              </v-btn>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            {{ $t('message.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

.loading-partial {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  z-index: 999;
  overflow-y: hidden;
}

</style>


<script>

import axios from "axios";
import config from '../../../config'

export default {
  name: 'ResetPasswordLinkDialog',
  data: () => ({
    loading: false,
    error: false,
    message: null,
    user: null,
    resetUrl: null,
    passwordResetExpiresAt: null
  }),
  props: {
    resetPasswordLinkDialogState: Boolean,
  },
  created () {
    this.$root.$refs.ResetPasswordLinkDialog = this;
    this.loading = true;
  },
  methods: {
    activateDialog(user){
      this.user = user;
      this.loading = true;
      this.error = false;
      this.message= null;
      this.resetUrl= null;
      this.passwordResetExpiresAt= null;

      this.getLink(user);
    },
    async copyLink() {
     const copy = await navigator.clipboard.writeText(this.resetUrl).then(() => true, () => false).catch(() => false)

     if (copy) {
       this.$store.dispatch('messages/addMessage', { text: this.$t('message.link_copied'), type: 'green' })
     } else {
       this.$store.dispatch('messages/addMessage', { text: this.$t('message.link_copy_failed'), type: 'red' })
     }
    },
    generateLink(user) {
      this.loading = true;

      let options = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        params: {
          user: user.id
        }
      };

      const data = {
        email: user.email
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      axios
        .post(config.apiUrl + '/reset-password-link', formData, options)
        .then(response => {
          this.resetUrl = response.data.reset_url;
          this.passwordResetExpiresAt = response.data.user.passwordResetExpiresAt;
          this.loading = false;
          this.error = false;
        })
        .catch(e => {
          const response = e.response

          this.loading = false
          this.error = true;

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
            if (response.data && response.data.violations instanceof Array) {
              response.data.violations.forEach(message => {
                this.message = message.title;
              });
            }
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        });
    },
    closeDialog() {
      this.user = null;
      this.$emit('closeResetPasswordLinkDialog');
    },
    getLink(user) {
      let options = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        params: {
          user: user.id
        }
      };

      axios
        .get(config.apiUrl + '/reset-password-link', options)
        .then(response => {
          this.resetUrl = response.data.reset_url;
          this.passwordResetExpiresAt = response.data.user.passwordResetExpiresAt;
          this.loading = false;
        })
        .catch(e => {
          const response = e.response

          this.loading = false
          this.error = true;

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
            if (response.data && response.data.violations instanceof Array) {
              response.data.violations.forEach(message => {
                this.message = message.title;
              });
            }
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        })
    },
  }
}
</script>
