var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"content"}},[(_vm.loading)?_c('div',{attrs:{"id":"loading"}},[_c('div',{staticClass:"dot-flashing"})]):_vm._e(),_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{attrs:{"id":"email_form"}},[_c('v-alert',{attrs:{"text":"","dense":"","color":"teal","border":"left","type":"success","dismissible":""},model:{value:(_vm.alertSuccess),callback:function ($$v) {_vm.alertSuccess=$$v},expression:"alertSuccess"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]),(_vm.errors.length)?_c('p',_vm._l((_vm.errors),function(error,index){return _c('v-alert',{key:index,attrs:{"text":"","dense":"","type":"error","dismissible":""},model:{value:(_vm.alertError),callback:function ($$v) {_vm.alertError=$$v},expression:"alertError"}},[_vm._v(" "+_vm._s(error.title)+" ")])}),1):_vm._e()],1),_c('v-container',{staticClass:"float-left"},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.companies,"label":"Entreprise","item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.getUsers()}},model:{value:(_vm.currentCompany),callback:function ($$v) {_vm.currentCompany=$$v},expression:"currentCompany"}})],1),_c('v-col',{staticClass:"tags-search",attrs:{"cols":"3","sm":"3"}},[_c('vue-tags-input',{attrs:{"tags":_vm.tags,"placeholder":"Recherche"},on:{"tags-changed":function (newTags) {
              _vm.tags = newTags
              _vm.search()
            }},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}})],1)],1)],1),_c('div',{staticClass:"mb-4"},[_c('table',{staticClass:"mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2"},[_vm._m(1),_c('tbody',_vm._l((_vm.users),function(user,userIndex){return _c('tr',{key:userIndex},[_c('td',{attrs:{"data-label":"Nom :"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(user.lastName))])]),_c('td',{attrs:{"data-label":"Prénom :"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(user.firstName))])]),_c('td',{staticClass:"sm-hidden",attrs:{"data-label":"Matricule(s) :"}},[_c('span',[_vm._v(_vm._s(user.employeeIds))])]),_c('td',{staticClass:"to-none",attrs:{"data-label":"Email :"}},[_c('span',[_vm._v(_vm._s(user.email ? user.email : '-'))]),(user.newEmail)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"update-email",attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" fa-info-circle ")])]}}],null,true)},[_c('span',[_vm._v(" Mise à jour d'e-mail en cours vers "),_c('strong',[_vm._v(_vm._s(user.newEmail))]),_c('strong',[(_vm.isChangeEmailTokenExpired(user.changeEmailExpiresAt))?_c('span',{staticClass:"red--text"},[_vm._v(" révoqué")]):_vm._e()])])]):_vm._e(),(_vm.hasRole('ROLE_UPDATE_USER_EMAIL', _vm.currentCompany))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"update-email",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openUpdateUserDialog(user)}}},'v-icon',attrs,false),on),[_vm._v(" fa-pen ")])]}}],null,true)},[_c('span',[_vm._v("Editer")])]):_vm._e()],1),_c('td',{staticClass:"sm-hidden",attrs:{"data-label":"Date de création :"}},[_vm._v(" "+_vm._s(user.createdAt ? _vm.formatDate(user.createdAt) : '-')+" ")]),_c('td',{staticClass:"sm-hidden",attrs:{"data-label":"Dernière connexion :"}},[_c('span',{staticClass:"ml-i1"},[_vm._v(_vm._s(user.lastLoginAt ? _vm.formatDate(user.lastLoginAt) : '-'))])]),_c('td',{staticClass:"sm-hidden",attrs:{"data-label":"Date d'invitation :"}},[_c('span',{staticClass:"ml-i1"},[_vm._v(_vm._s(user.invitationSentAt ? _vm.formatDate(user.invitationSentAt) : '-'))])]),_c('td',{staticClass:"sm-hidden",attrs:{"data-label":"Etat :"}},[(user.accountSettedUp)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"teal","text-color":"white"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1),_vm._v(" Actif ")],1):_vm._e(),(!user.accountSettedUp)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1),_vm._v(" Inactif ")],1):_vm._e()],1),_c('td',{staticClass:"sm-hidden",attrs:{"data-label":"Souscription :"}},[_c('v-switch',{attrs:{"disabled":!_vm.hasRole('ROLE_UPDATE_SUBSCRIPTION', _vm.currentCompany)},on:{"change":function($event){return _vm.putVault(user)}},model:{value:(user.subscribed),callback:function ($$v) {_vm.$set(user, "subscribed", $$v)},expression:"user.subscribed"}})],1),_c('td',{staticClass:"actions to-none",attrs:{"data-label":"Actions :"}},[(_vm.hasRole('ROLE_READ_TRUSTED_CONTACT', _vm.currentCompany))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openTrustedContactDialog(user)}}},'v-icon',attrs,false),on),[_vm._v(" fa-user-friends ")])]}}],null,true)},[_c('span',[_vm._v("Voir le tiers de confiance")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openLastBulletinsDialog(user)}}},'v-icon',attrs,false),on),[_vm._v(" fa-file-invoice-dollar ")])]}}],null,true)},[_c('span',[_vm._v("Voir la date des derniers bulletins")])]),(!user.accountSettedUp && user.email)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openInviteUserDialog(user)}}},'v-icon',attrs,false),on),[_vm._v(" fa-paper-plane ")])]}}],null,true)},[_c('span',[_vm._v("Envoyer une invitation")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mdi mdi-key-link icon-size",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openResetPasswordLinkDialog(user)}}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Réinitialisation du mot de passe")])])],1)])}),0)])])],1),_c('UpdateUserDialog',{key:Date.now(),attrs:{"update-user-dialog-state":_vm.updateUserDialogState,"user":_vm.userToUpdate,"company":_vm.currentCompany},on:{"closeUpdateUserDialog":function($event){return _vm.closeUpdateUserDialog($event)}}}),_c('InviteUserDialog',{key:Date.now() + 1,attrs:{"invite-user-dialog-state":_vm.inviteUserDialogState,"user":_vm.userToInvite},on:{"closeInviteUserDialog":function($event){return _vm.closeInviteUserDialog($event)}}}),_c('TrustedContactDialog',{key:Date.now() + 2,attrs:{"trusted-contact-dialog-state":_vm.trustedContactDialogState,"trustedContact":_vm.userTrustedContact},on:{"closeTrustedContactDialog":function($event){return _vm.closeTrustedContactDialog($event)}}}),_c('LastBulletinsDialog',{attrs:{"last-bulletin-dialog-state":_vm.lastBulletinDialogState},on:{"closeLastBulletinsDialog":function($event){_vm.lastBulletinDialogState = false}}}),_c('ResetPasswordLinkDialog',{attrs:{"reset-password-link-dialog-state":_vm.resetPasswordLinkDialogState},on:{"closeResetPasswordLinkDialog":function($event){_vm.resetPasswordLinkDialogState = false}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('h1',[_vm._v("Administration des salariés")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nom")]),_c('th',[_vm._v("Prénom")]),_c('th',{staticClass:"sm-hidden"},[_vm._v("Matricule(s)")]),_c('th',[_vm._v("Email")]),_c('th',{staticClass:"sm-hidden"},[_vm._v("Date de création")]),_c('th',{staticClass:"sm-hidden"},[_vm._v("Dernière connexion")]),_c('th',{staticClass:"sm-hidden"},[_vm._v("Date d'invitation")]),_c('th',{staticClass:"sm-hidden"},[_vm._v("Etat du compte")]),_c('th',{staticClass:"sm-hidden"},[_vm._v("Souscription")]),_c('th',[_vm._v("Actions")])])])}]

export { render, staticRenderFns }