<template>
  <div class="text-center">
    <v-dialog
      v-model="lastBulletinDialogState"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Derniers bulletins de paie
        </v-card-title>

        <v-card-text class="mt-7">

          <div v-if="loading" class="loading-partial mx-5">
            <div class="dot-flashing mt-5 mb-5"></div>
          </div>

          <div v-if="!loading && lastDocumentsCreatedAt.length == 0 && !error" class="empty-result">
            <div>
              <i class="mdi mdi-alert-circle"></i>
              Il n'y a pas de documents
            </div>
          </div>

          <div v-if="error">
            <v-alert class="text-white" color="red lighten-2" dense>
              <i class="mdi mdi-alert-circle"></i> {{ message }}
            </v-alert>
          </div>

          <div v-if="!loading && !error && lastDocumentsCreatedAt.length != 0">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-center">
                    Date
                  </th>
                  <th class="text-center">
                    Status
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="document in lastDocumentsCreatedAt" :key="document.id">
                  <td>
                    {{ document.name }}
                  </td>
                  <td class="text-center">
                    {{ formatDate(document.createdAt) }}
                  </td>
                  <td class="text-center">
                    <v-chip :color=getColorStatus(document.status) class="ml-0 mr-1 white--text" small>
                      {{ getLabelStatus(document.status) }}
                    </v-chip>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeLastBulletinsDialog()">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<style scoped>

.loading-partial {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  z-index: 999;
  overflow-y: hidden;
}

.empty-result {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  z-index: 999;
  overflow-y: hidden;
}
</style>

<script>
import axios from "axios";
import config from "../../../config";

export default {
  name: 'LastBulletinsDialog',
  data: () => ({
    lastDocumentsCreatedAt: [],
    loading: false,
    error: false,
    message: null
  }),
  props: {
    lastBulletinDialogState: Boolean,
    createdAt: String
  },
  created () {
    this.$root.$refs.LastBulletinsDialog = this;
    this.loading = true;
    this.error = false;
    this.message = null;
  },
  methods: {
    closeLastBulletinsDialog() {
      this.lastDocumentsCreatedAt = [];
      this.error = false;
      this.loading = false;
      this.message = null;
      this.$emit('closeLastBulletinsDialog');
    },
    activateDialog(user){
      this.getLastDocumentCreatedBySection(user);
      this.lastDocumentsCreatedAt = [];
      this.loading = true;
    },
    getLabelStatus(status){
      const options = {
        done: 'Déposé',
        error: 'Erreur',
        pending: 'en attendant'
      }

      return options[status];
    },
    getColorStatus(status){
      const options = {
        done: 'teal',
        error: 'red',
        pending: 'primary'
      }

      return options[status]
    },
    getLastDocumentCreatedBySection(user) {
      let options = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        params: {
          vault: user.vaultId,
          user: user.id,
          term: 'payslip',
          limit: 10
        }
      }

      axios
        .get(config.apiUrl + '/last-documents-created-by-section', options)
        .then(response => {
          this.loading = false;
          this.lastDocumentsCreatedAt = response.data ? response.data : null;
        })
        .catch(e => {
          const response = e.response

          this.loading = false
          this.error = true

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
            if (response.data && response.data.violations instanceof Array) {
              response.data.violations.forEach(message => {
                this.message = message.title
                this.$store.dispatch('messages/addMessage', { text: message.title});
              });
            }
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        })

    },
    formatDate (currentDate) {
      const timestamp = Date.parse(currentDate)
      const date = new Date(timestamp)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let hour = date.getHours()
      let min = date.getMinutes()

      day = (day < 10 ? '0' : '') + day
      month = (month < 10 ? '0' : '') + month
      hour = (hour < 10 ? '0' : '') + hour
      min = (min < 10 ? '0' : '') + min

      return day + '/' + month + '/' + date.getFullYear() + ' ' + hour + ':' + min
    }
  }
}
</script>
