<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>Administration des salariés</h1>
      </header>

      <div id="email_form">
        <v-alert
          v-model="alertSuccess"
          text
          dense
          color="teal"
          border="left"
          type="success"
          dismissible
        >
          {{ successMessage }}
        </v-alert>

        <p v-if="errors.length">
          <v-alert
            v-model="alertError"
            text
            dense
            type="error"
            dismissible
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>
      </div>

      <v-container class="float-left">
        <v-row>
          <v-col class="d-flex" cols="3" sm="3">
            <v-select
              v-model="currentCompany"
              :items="companies"
              label="Entreprise"
              item-text="name"
              item-value="id"
              @change="getUsers()"
            ></v-select>
          </v-col>

          <v-col class="tags-search" cols="3" sm="3">
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              @tags-changed="newTags => {
                tags = newTags
                search()
              }"
              placeholder="Recherche"
            />
          </v-col>
        </v-row>
      </v-container>

      <div class="mb-4">
        <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2" >
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th class="sm-hidden">Matricule(s)</th>
              <th>Email</th>
              <th class="sm-hidden">Date de création</th>
              <th class="sm-hidden">Dernière connexion</th>
              <th class="sm-hidden">Date d'invitation</th>
              <th class="sm-hidden">Etat du compte</th>
              <th class="sm-hidden">Souscription</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, userIndex) in users"
              :key="userIndex"
            >
              <td data-label="Nom :">
                <span class="font-weight-bold">{{ user.lastName }}</span>
              </td>
              <td data-label="Prénom :">
                <span class="font-weight-bold">{{ user.firstName }}</span>
              </td>
              <td class="sm-hidden" data-label="Matricule(s) :">
                <span>{{ user.employeeIds }}</span>
              </td>
              <td class="to-none" data-label="Email :">
                <span>{{ user.email ? user.email : '-' }}</span>
                <v-tooltip bottom v-if="user.newEmail">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="update-email" dark v-bind="attrs" v-on="on">
                      fa-info-circle
                    </v-icon>
                  </template>
                  <span>
                    Mise à jour d'e-mail en cours vers <strong>{{ user.newEmail }}</strong>
                    <strong><span v-if="isChangeEmailTokenExpired(user.changeEmailExpiresAt)" class="red--text"> révoqué</span></strong>
                  </span>
                </v-tooltip>

                <v-tooltip bottom v-if="hasRole('ROLE_UPDATE_USER_EMAIL', currentCompany)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="update-email" dark v-bind="attrs" v-on="on" @click="openUpdateUserDialog(user)">
                      fa-pen
                    </v-icon>
                  </template>
                  <span>Editer</span>
                </v-tooltip>
              </td>
              <td class="sm-hidden" data-label="Date de création :">
                {{ user.createdAt ? formatDate(user.createdAt) : '-' }}
              </td>
              <td class="sm-hidden" data-label="Dernière connexion :">
                <span class="ml-i1">{{ user.lastLoginAt ? formatDate(user.lastLoginAt) : '-' }}</span>
              </td>
              <td class="sm-hidden" data-label="Date d'invitation :">
                <span class="ml-i1">{{ user.invitationSentAt ? formatDate(user.invitationSentAt) : '-' }}</span>
              </td>
              <td class="sm-hidden" data-label="Etat :">
                <v-chip v-if="user.accountSettedUp" class="ma-2" color="teal" text-color="white">
                  <v-avatar left>
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  Actif
                </v-chip>
                <v-chip v-if="!user.accountSettedUp" class="ma-2" color="red" text-color="white">
                  <v-avatar left>
                    <v-icon>mdi-close-circle</v-icon>
                  </v-avatar>
                  Inactif
                </v-chip>
              </td>
              <td class="sm-hidden" data-label="Souscription :">
                <v-switch :disabled="!hasRole('ROLE_UPDATE_SUBSCRIPTION', currentCompany)" v-model="user.subscribed" v-on:change="putVault(user)"></v-switch>
              </td>
              <td class="actions to-none" data-label="Actions :">
                <v-tooltip bottom v-if="hasRole('ROLE_READ_TRUSTED_CONTACT', currentCompany)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="openTrustedContactDialog(user)">
                      fa-user-friends
                    </v-icon>
                  </template>
                  <span>Voir le tiers de confiance</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="openLastBulletinsDialog(user)">
                      fa-file-invoice-dollar
                    </v-icon>
                  </template>
                  <span>Voir la date des derniers bulletins</span>
                </v-tooltip>

                <v-tooltip v-if="!user.accountSettedUp && user.email" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="openInviteUserDialog(user)">
                      fa-paper-plane
                    </v-icon>
                  </template>
                  <span>Envoyer une invitation</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mdi mdi-key-link icon-size" color="primary" dark v-bind="attrs" v-on="on" @click="openResetPasswordLinkDialog(user)">
                    </v-icon>
                  </template>
                  <span>Réinitialisation du mot de passe</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <UpdateUserDialog
      :key="Date.now()"
      :update-user-dialog-state="updateUserDialogState"
      :user="userToUpdate"
      :company="currentCompany"
      @closeUpdateUserDialog="closeUpdateUserDialog($event)"
    ></UpdateUserDialog>

    <InviteUserDialog
      :key="Date.now() + 1"
      :invite-user-dialog-state="inviteUserDialogState"
      :user="userToInvite"
      @closeInviteUserDialog="closeInviteUserDialog($event)"
    ></InviteUserDialog>

    <TrustedContactDialog
      :key="Date.now() + 2"
      :trusted-contact-dialog-state="trustedContactDialogState"
      :trustedContact="userTrustedContact"
      @closeTrustedContactDialog="closeTrustedContactDialog($event)"
    ></TrustedContactDialog>

    <LastBulletinsDialog
    :last-bulletin-dialog-state="lastBulletinDialogState"
    @closeLastBulletinsDialog="lastBulletinDialogState = false"
    ></LastBulletinsDialog>

    <ResetPasswordLinkDialog
      :reset-password-link-dialog-state="resetPasswordLinkDialogState"
      @closeResetPasswordLinkDialog="resetPasswordLinkDialogState = false"
    ></ResetPasswordLinkDialog>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'
import UpdateUserDialog from '../../components/Dialog/Users/UpdateUserDialog'
import InviteUserDialog from '../../components/Dialog/Users/InviteUserDialog'
import TrustedContactDialog from '../../components/Dialog/Users/TrustedContactDialog'
import LastBulletinsDialog from '../../components/Dialog/Users/LastBulletinsDialog'
import VueTagsInput from '@johmun/vue-tags-input'
import axios from "axios";
import slugify from "slugify";
import ResetPasswordLinkDialog from "../../components/Dialog/Users/ResetPasswordLinkDialog";

export default {
  name: 'Users',
  data: () => ({
    alertSuccess: false,
    alertError: false,
    successMessage: null,
    errors: [],
    loading: false,
    updateUserDialogState: false,
    inviteUserDialogState: false,
    lastBulletinDialogState: false,
    trustedContactDialogState: false,
    resetPasswordLinkDialogState: false,
    userToUpdate: null,
    userToInvite: null,
    userTrustedContact: null,
    currentCompany: null,
    nextPage: 1,
    tag: '',
    tags: []
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.currentCompany = this.companies[0].id
    this.getUsers()
    document.title = config.title + ' - Gestion des utilisateurs'

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)

    this.$store.commit('setUsers', [])
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    users () {
      return this.$store.getters.getUsers
    },
    companies () {
      return this.$store.getters.getCompaniesWhereAdmin
    },
    isHandlingUserScroll () {
      return this.$store.getters.isHandlingUserScroll
    }
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = wrapperOffsetHeight - (document.documentElement.scrollTop + window.innerHeight) <= 200

      if (bottomOfWindow && !this.isHandlingUserScroll) {
        this.getUsers()
      }
    },
    getUsers () {
      this.loading = true

      const params = {
        company: this.currentCompany,
        page: this.nextPage
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      this.$store.commit('setHandlingUserScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/users', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const users = response.data

            if (users && users.length) {
              this.$store.commit('addUsers', users)

              this.nextPage = this.nextPage + 1
            }
          }
          this.loading = false
          this.$store.commit('setHandlingUserScroll', false)

        })
        .catch(e => {
          this.loading = false
          this.$store.commit('setHandlingUserScroll', false)
        })
    },
    putVault (user) {
      const data = {
        subscribed: user.subscribed
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/vaults/' + user.vaultId, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const vault = response.data

            if (vault) {
              this.alertSuccess = true
              this.successMessage = 'La souscription a été mise à jour.'
              this.errors = []
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response && (response.status === 400 || response.status === 404)) {
            const data = response.data
            this.errors = data.violations
            this.alertError = true
            this.successMessage = null
            this.alertSuccess = false
          }

          this.loading = false
        })
    },
    search () {
      const params = {
        company: this.currentCompany,
        page: 1
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/users', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const users = response.data

            users.length ? this.$store.commit('setUsers', users) : this.$store.commit('setUsers', [])

            this.nextPage = 2
          }
        })
        .catch(e => {
        })
    },
    openUpdateUserDialog (user) {
      this.updateUserDialogState = true
      this.userToUpdate = user
    },
    closeUpdateUserDialog () {
      this.updateUserDialogState = false
    },
    openInviteUserDialog (user) {
      this.inviteUserDialogState = true
      this.userToInvite = user
    },
    closeInviteUserDialog () {
      this.inviteUserDialogState = false
    },
    openTrustedContactDialog (user) {
      this.trustedContactDialogState = true
      this.userTrustedContact = user.trustedContactEmail ? {
        lastName: user.trustedContactLastName,
        firstName: user.trustedContactFirstName,
        email: user.trustedContactEmail,
        address: user.trustedContactAddress
      } : null
    },
    closeTrustedContactDialog () {
      this.trustedContactDialogState = false
    },
    hasRole (role, company) {
      return this.$store.getters.hasRole(role, company)
    },
    isChangeEmailTokenExpired (changeEmailExpiresAt) {
      if (!changeEmailExpiresAt) {
        return false
      }

      return new Date() > new Date(changeEmailExpiresAt)
    },
    formatDate (currentDate) {
      const timestamp = Date.parse(currentDate)
      const date = new Date(timestamp)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let hour = date.getHours()
      let min = date.getMinutes()

      day = (day < 10 ? '0' : '') + day
      month = (month < 10 ? '0' : '') + month
      hour = (hour < 10 ? '0' : '') + hour
      min = (min < 10 ? '0' : '') + min

      return day + '/' + month + '/' + date.getFullYear() + ' ' + hour + ':' + min
    },
    openLastBulletinsDialog (user) {
      this.$root.$refs.LastBulletinsDialog.activateDialog(user);
      this.lastBulletinDialogState = true
    },
    openResetPasswordLinkDialog(user) {
      this.$root.$refs.ResetPasswordLinkDialog.activateDialog(user);
      this.resetPasswordLinkDialogState = true
    },
  },
  components: {
    ResetPasswordLinkDialog,
    LastBulletinsDialog,
    TrustedContactDialog,
    UpdateUserDialog,
    InviteUserDialog,
    VueTagsInput
  }
}
</script>
